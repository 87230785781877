<template>
  <div>
    <h2> ยอดซื้อ</h2>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <h3
              class="text-center"
            >
              สรุปยอดซื้อ
            </h3>

            <br>
            <br>
            <h1 class="text-center">
              500,000
            </h1>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <br><br><br>
            <h3
              class="text-center"
            >
              รายงาน
            </h3>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-data-table
          :headers="headers"
          :items="reports"
          :search="search"
          :server-items-length="total"
          :options.sync="serverOptions"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- <v-toolbar-title> -->
              <v-text-field
                v-model="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <!-- </v-toolbar-title> -->
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
              <v-spacer></v-spacer>

              <!--              <v-dialog-->
              <!--                v-model="dialogDelete"-->
              <!--                max-width="600px"-->
              <!--              >-->
              <!--                <v-card>-->
              <!--                  <v-card-title class="text-h5 mb-5">-->
              <!--                    Are you sure you want to delete this item?-->
              <!--                  </v-card-title>-->
              <!--                  <v-card-actions>-->
              <!--                    <v-spacer></v-spacer>-->
              <!--                    <v-btn-->
              <!--                      color="error"-->
              <!--                      outlined-->
              <!--                      @click="closeDelete"-->
              <!--                    >-->
              <!--                      Cancel-->
              <!--                    </v-btn>-->
              <!--                    <v-btn-->
              <!--                      color="primary"-->
              <!--                      @click="deleteItemConfirm"-->
              <!--                    >-->
              <!--                      OK-->
              <!--                    </v-btn>-->
              <!--                    <v-spacer></v-spacer>-->
              <!--                  </v-card-actions>-->
              <!--                </v-card>-->
              <!--              </v-dialog>-->
            </v-toolbar>
          </template>
          <template v-slot:item.index="{ index }">
            {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
          </template>
          <template v-slot:item.emp_date="{ item }">
            <span v-if="item.mem_date && item.mem_time">
              {{ item.mem_date }} {{ item.emp_time }}
            </span>
            <span v-if="!item.mem_date || !item.mem_time">
              {{ item.emp_date }} {{ item.mem_time }}
            </span>
          </template>
          <template v-slot:item.description="{ item }">
            <div v-if="item.order && item.order.order_inv">
              <span v-if="item.credit_product_amt > 1">สั่งซื้อสินค้า</span>
            </div>
            <div v-if="item.member_package && item.member_package.mem_package_no">
              <span v-if="item.credit_product_amt > 1">สั่งซื้อแพ็กเกจ</span>
              <span v-if="item.credit_product_amt <= 1">สั่งซื้อสินค้า</span>
            </div>
            <div v-if="item.claim_id">
              <span v-if="item.credit_product_amt > 1">คืนเครดิตสินค้า</span>
            </div>
          </template>
          <template v-slot:item.credit_product_amt="{ item }">
            <span v-if="item.credit_product_amt > 0">{{ item.credit_product_amt }}</span>
          </template>
          <template v-slot:item.credit_product_amount="{ item }">
            <span v-if="item.credit_product_amt < 0">{{ item.credit_product_amt }}</span>
          </template>
          <template v-slot:item.order_id="{ item }">
            <span v-if="item.order">
              {{ item.order_inv }}
            </span>
            <span v-if="item.member_package">
              {{ item.member_package.mem_package_no }}
            </span>
            <span v-if="item.claim_id ">
              {{ item.claim_id }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </template>
          <template v-slot:no-data>
            <!-- <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import Swal from 'sweetalert2'

import { Bar, Pie, Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
  PointElement,
  LineElement,
} from 'chart.js'
import instance_pattern from '@/services/pattern'
import instance_report from '@/services/report'

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'ValidName',
  components: {
    Swal, Bar, Pie, LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
      },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    testData: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [40, 39, 10, 40, 39, 80, 40],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
    configs: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    chartData: {
      labels: [
        '',
        'มิ.ย./ 2566',
        'ก.ค./ 2566',
        'ส.ค./ 2566',
        'ก.ย./ 2566',
        '',
      ],
      datasets: [
        {
          label: 'ยอดซื้อรวม',
          backgroundColor: ['#f87979', '#3E80E1'],
          data: [40, 80, 20, 15, 70, 33],
        },
      ],

    },
    chartOptions: {
      responsive: true,
    },
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    reports: [],
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'รหัสสินค้า', value: 'emp_date' },
      { text: 'สินค้า', value: 'order_id' },
      { text: 'จำนวน', value: 'description' },
      { text: 'ยอดซื้อ (บาท)', value: 'credit_product_amt' },
      { text: 'ยอดซื้อ (%)', value: 'remain' },
    ],
    report: {},
    editedIndex: -1,
    date: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    const date = new Date()
    const day = date.getDate()
    const last_month = date.getMonth()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    // this.date = `${year}-${String(month).padStart(2, '0')}-${day}`;

    this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`)
    this.date.push(`${year}-${String(month).padStart(2, '0')}-${day}`)
    this.getReport()
  },

  methods: {

    deleteFilePattern() {
      // Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!'
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     Swal.fire(
      //       'Deleted!',
      //       'Your file has been deleted.',
      //       'success'
      //     )
      //   }
      // })
      // return false;
      Swal.fire({
        title: 'คุณแน่ใจต้องการลบไฟล์สั่งเพิ่มเติม !',
        text: 'ลบไฟล์สั่งเพิ่มเติม หลัง 30 วัน ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          // let loader = this.$loading.show({
          //   loader: 'dots',
          //   color: '#27ae60'
          // });
          if (willDelete.isConfirmed) {
            instance_pattern.deleteFile().then(res => {
              Swal.fire({
                icon: 'success',
                title: 'ลบไฟล์สั่งเพิ่มเติม หลัง 30 วัน สำเสร็จ',
                showConfirmButton: false,
                timer: 1500,
              })
            }).catch(err => {
              Swal.fire({
                icon: 'error',
                title: err.message,
                showConfirmButton: false,
                timer: 1500,
              })
            })

            //   axios.get('{{url('admin/deleteFilePattern')}}')
            // .then(response => {
            //     loader.hide()
            //
            //   })
            //     .catch(error => {
            //       loader.hide()
            //       this.swalAlert(error.message, 'error')
            //       console.log(error.message);
            //     });
          } else {
            Swal.fire('แน่ใจเเล้วสามารถยืนยันการ ลบไฟล์สั่งเพิ่มเติมหลัง 30 วัน ได้ทันที')

            //   swal("แน่ใจเเล้วสามารถยืนยันการ ลบไฟล์สั่งเพิ่มเติมหลัง 30 วัน ได้ทันที");
            //
          }
        })
    },
    editItem(item) {
      this.editedIndex = this.configs.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    getReport() {
      this.$store.state.isLoading = true
      const data = {
        search: '',
        date: this.date,
      }

      instance_report.getHomeReport(data).then(res => {
        this.report = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    openLink(item) {
      // date=2023-05-24&date=2023-06-24
      // 2023/05/24%20-%202023/06/24
      this.$router.push({
        name: item.to.name,
        query: { search: item.search, status: item.status_th, date: this.date.toString() },
      })

      // router.push(})
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
