var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" ยอดซื้อ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{staticStyle:{"min-height":"180px"}},[_c('v-card-text',[_c('h3',{staticClass:"text-center"},[_vm._v(" สรุปยอดซื้อ ")]),_c('br'),_c('br'),_c('h1',{staticClass:"text-center"},[_vm._v(" 500,000 ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{staticStyle:{"min-height":"180px"}},[_c('v-card-text',[_c('br'),_c('br'),_c('br'),_c('h3',{staticClass:"text-center"},[_vm._v(" รายงาน ")]),_c('LineChartGenerator',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"server-items-length":_vm.total,"options":_vm.serverOptions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.serverOptions=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(((_vm.serverOptions.page - 1) * _vm.serverOptions.itemsPerPage) + index + 1)+" ")]}},{key:"item.emp_date",fn:function(ref){
var item = ref.item;
return [(item.mem_date && item.mem_time)?_c('span',[_vm._v(" "+_vm._s(item.mem_date)+" "+_vm._s(item.emp_time)+" ")]):_vm._e(),(!item.mem_date || !item.mem_time)?_c('span',[_vm._v(" "+_vm._s(item.emp_date)+" "+_vm._s(item.mem_time)+" ")]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.order && item.order.order_inv)?_c('div',[(item.credit_product_amt > 1)?_c('span',[_vm._v("สั่งซื้อสินค้า")]):_vm._e()]):_vm._e(),(item.member_package && item.member_package.mem_package_no)?_c('div',[(item.credit_product_amt > 1)?_c('span',[_vm._v("สั่งซื้อแพ็กเกจ")]):_vm._e(),(item.credit_product_amt <= 1)?_c('span',[_vm._v("สั่งซื้อสินค้า")]):_vm._e()]):_vm._e(),(item.claim_id)?_c('div',[(item.credit_product_amt > 1)?_c('span',[_vm._v("คืนเครดิตสินค้า")]):_vm._e()]):_vm._e()]}},{key:"item.credit_product_amt",fn:function(ref){
var item = ref.item;
return [(item.credit_product_amt > 0)?_c('span',[_vm._v(_vm._s(item.credit_product_amt))]):_vm._e()]}},{key:"item.credit_product_amount",fn:function(ref){
var item = ref.item;
return [(item.credit_product_amt < 0)?_c('span',[_vm._v(_vm._s(item.credit_product_amt))]):_vm._e()]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [(item.order)?_c('span',[_vm._v(" "+_vm._s(item.order_inv)+" ")]):_vm._e(),(item.member_package)?_c('span',[_vm._v(" "+_vm._s(item.member_package.mem_package_no)+" ")]):_vm._e(),(item.claim_id )?_c('span',[_vm._v(" "+_vm._s(item.claim_id)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }